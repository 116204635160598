import React from "react"
import { Link, graphql } from "gatsby" //highlight-line
import Layout from "../components/layout"
import SEO from "../components/seo"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button, Row, Col, Image } from "react-bootstrap"
import styled from "styled-components";
import { Helmet } from "react-helmet";


const Section1 = styled.section`
.noBottomPadding {
    padding-bottom: 0px;
}
`;

export default function project({ data }) {
  return (
    <Layout>
      <Helmet>
        <script src="https://kit.fontawesome.com/bb17528802.js" crossOrigin="anonymous"></script>
      </Helmet>
      <SEO title="Project" />

      <Section1>
        <br></br>
        <Container>
          <Row xs={1} md={2} lg={2}>
            {data.allWpPost.nodes.map(node => (
              <Col md={6}>
                <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                  <div class="col p-4 d-flex flex-column position-static">
                    <h3 class="mb-0 SerifFont"><b>{node.title}</b></h3>
                    <br></br>
                    <Image variant="top" src={node.featuredImage?.node?.sourceUrl} width="852" height="480" alt={node.featuredImage?.node?.altText} fluid />
                    {/* <strong class="d-inline-block mb-2 text-primary">
                      {" "}
                      {node.categories.nodes.map((category, index) => [
                        index > 0 && ", ",
                        <Link key={index}>
                          {category.name}
                        </Link>,
                      ])}
                    </strong> */}
                    <br></br>
                    <span class="border-bottom border-dark"></span>



                    <strong class="d-inline-block mb-2">
                      {" "}
                      {node.tags.nodes.map((tag, index) => [
                        index > 0 && ", ",
                        <Link key={index}>
                          {tag.name}
                        </Link>,
                      ])}
                    </strong>
                    <a href={node.slug}><Button variant="dark">Read More</Button></a>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Section1>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allWpPost(filter: {categories: {nodes: {elemMatch: {name: {eq: "portfolio"}}}}}) {
      nodes {
        title
        excerpt
        slug
        uri
        date(formatString: "MMMM Do, YYYY")
        featuredImage {
          node {
            sourceUrl
            altText
          }
        }
        author {
          node {
            lastName
            firstName
            id
            avatar {
              url
            }
          }
        }
        tags {
          nodes {
            name
            link
          }
        }
        categories {
          nodes {
            name
            link
          }
        }
      }
    }
  }
`